import React from "react";
import ChangeLogs from "../components/sections/ChangeLogs";

const ChangeLog = () => {
	return (
		<>
			<ChangeLogs className="illustration-section-01" />
		</>
	);
};

export default ChangeLog;
